<template>
  <div class="audio">
    <div class="audio-file">
      <span>Аудио</span>
      <label for="audio-file">
        <input id="audio-file" type="file" placeholder="Введите название" @change="changeFile" />
        <span>Выберите файл</span>
      </label>
      <span></span>
      <div class="audio-name">{{ name }}</div>
    </div>
    <mts-button @click="submit" theme="dark" style="width: 130px">отправить</mts-button>
  </div>
</template>

<script>
export default {
  name: 'index-audio-page',
  components: {},
  data() {
    return {
      desc: '',
      res: '',
      file: null,
      name: ''
    }
  },
  computed: {},
  methods: {
    changeFile({ target }) {
      this.file = target.files[0]
      this.name = target.files[0].name
      target.value = ''
    },
    async submit() {}
  },
  async created() {}
}
</script>

<style lang="scss">
@import './styles';
</style>
